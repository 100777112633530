import { Alert, Button, Card, Form, Input } from 'antd';
import React, { useState } from 'react';
import { AuthApi } from '../../../services/api/Auth';
import './style.scss';

interface Props {}

enum Status {
	Success = 'success',
	Error = 'error',
}

const Grid = (props: Props) => {
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState<null | Status>(null);

	const onFinish = (values) => {
		setLoading(true);
		setStatus(null);

		AuthApi.changePassword(values)
			.then(() => {
				setStatus(Status.Success);
			})
			.catch(() => {
				setStatus(Status.Error);
			})
			.finally(() => setLoading(false));
	};

	return (
		<Card title="Change password">
			<Form name="basic" layout="vertical" onFinish={onFinish} autoComplete="off">
				<div className="flex gap-4 flex-wrap">
					<Form.Item
						label="Password"
						name="password"
						rules={[
							{
								required: true,
								message: 'Please confirm your password!',
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value || getFieldValue('confirmPassword') === value) {
										return Promise.resolve();
									}
									return Promise.reject(
										new Error('The two passwords that you entered do not match!'),
									);
								},
							}),
						]}
					>
						<Input.Password />
					</Form.Item>

					<Form.Item
						label="Confirm Password"
						name="confirmPassword"
						rules={[
							{
								required: true,
								message: 'Please confirm your password!',
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value || getFieldValue('password') === value) {
										return Promise.resolve();
									}
									return Promise.reject(
										new Error('The two passwords that you entered do not match!'),
									);
								},
							}),
						]}
					>
						<Input.Password />
					</Form.Item>
				</div>

				{status && (
					<Alert
						message={status === Status.Error ? 'Ошибка при смене пароля' : 'Пароль успешно изменен'}
						style={{ marginBottom: 20 }}
						type={status}
					></Alert>
				)}
				<Button loading={loading} type="primary" htmlType="submit">
					Submit
				</Button>
			</Form>
		</Card>
	);
};

export default Grid;
