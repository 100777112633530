import React from 'react';
import Grid from '../../modules/Settings/Grid/Grid';
import { Helmet } from 'react-helmet';
import withPrivateRoute from '../../hocs/withPrivateRoute';
import { Roles } from '../../utils/constants';

interface Props {}

const Settings = (props: Props) => {
	return (
		<>
			<Helmet>
				<title>Settings</title>
			</Helmet>
			<div className="settings page">
				<h1 className="page__title">Settings</h1>
				<Grid />
			</div>
		</>
	);
};

export default withPrivateRoute(Settings, [Roles.admin, Roles.user]);
